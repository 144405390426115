.login {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  .logo {
    width: 100%;
    height: auto;
    display: block;
    padding: 20px 0;
  }

  .form {
    // border: 1pt solid red;
  }
}
