$primaryColor: #0d6efd;
$secondaryColor: #013289;
$baseColor: #fefefe;
$lightBaseColor: #e9e9e9;
$darkColor: #111;
$secondaryDarkColor: #333;
$secondaryLightColor: #afafaf;
$softShadow: #eee;
$orangeColor: #ffc107;
$yellowColor: #f6fa31;
$redColor: #ff6f6f;
$greenColor: #00cc00;

$mobile: 426px;
$tablet: 768px;
$laptop: 1024px;
$laptopL: 1440px;

$dots: #444cf7;
