html,
body {
  font-family: 'Poppins', sans-serif;
  min-height: 100vh;
}

a {
  text-decoration: none;
}

.page {
  padding: 75px 20px 0 20px;
}

.page-title {
  font-size: 2rem;
  text-align: center;
  font-weight: 500;
  color: $primaryColor;
}

// bootstrap blue glow
.form-select:focus,
.form-select:active {
  outline: none !important;
  box-shadow: none !important;
}

@media print {
  @page {
    size: 50mm 300mm;
  }
}
