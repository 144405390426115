.data-card {
  cursor: pointer;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transform: scale(1);
  transition: transform 0.3s;
  padding: 20px 20px;

  &:hover {
    transform: scale(1.025);

    @media only screen and (max-width: $tablet) {
      transform: scale(1.02);
    }
  }

  .left {
    &-heading {
      font-family: 'Poppins', sans-serif;
      font-size: 1.25rem;
    }

    &-text {
      font-family: 'Poppins', sans-serif;
      font-size: 2.25rem;
      margin: 0;
    }
  }

  .right {
    padding: 0;

    &-image {
      color: #fff;
      border-radius: 20px;
      height: 40px;
      width: 40px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      &-img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
