@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,900;1,300&display=swap');

@import './variables.scss';
@import './common.scss';
@import './navbar.scss';
@import './login.scss';
@import './new-user.scss';
@import './dashboard.scss';
@import './new-town.scss';
@import './card.scss';
